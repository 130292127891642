body {
  background-color: #ffecb3;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
